import React from "react";

import SkipNavigation from "@Components/common/SkipNavigation";
import Header from "@Components/common/Header";
import Footer from "@Components/common/Footer";

import Profile from "@Components/Profile";

import classNames from "classnames/bind";
import style from "./CareerLayout.module.scss";
const cx = classNames.bind(style);

type Props = {
  menuItem?: any;
};

const ProfileLayout: React.FC<Props> = ({ menuItem, rootPage }: Props) => {
  return (
    <>
      <SkipNavigation />
      <div id="wrap">
        <Header
          title={"👨‍💻 Jaesun 👨‍💻"}
          menuItem={menuItem}
          rootPage={rootPage}
        />

        <section id="content" className={cx("content", "type_main")}>
          <div className={cx("content_inner")}>
            <Profile
              title={menuItem.career["title"]}
              name={menuItem.career["name"]}
              id={menuItem.career["id"]}
            />
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default ProfileLayout;
