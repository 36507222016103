import { useState, useEffect } from "react";

import classNames from "classnames/bind";

import style from "./Header.module.scss";

const cx = classNames.bind(style);

type Props = {
  title?: any;
  menuItem?: any;
  rootPage?: any;
};

const Header: React.FC<Props> = ({ title, menuItem, rootPage }: Props) => {
  const url = window.location.href;
  function gnbActive(id) {
    return url.indexOf(id) != -1
      ? url.substring(url.indexOf(id), url.indexOf(id) + id.length)
      : "";
  }
  // const [ScrollY, setScrollY] = useState(); // window 의 pageYOffset값을 저장
  // const [ScrollActive, setScrollActive] = useState(false);
  // function handleScroll() {
  //   if (ScrollY > 200 || !ScrollY) {
  //     setScrollY(window.pageYOffset);
  //     setScrollActive(true);
  //   } else {
  //     setScrollY(window.pageYOffset);
  //     setScrollActive(false);
  //   }
  // }
  // useEffect(() => {
  //   console.log(ScrollY);
  //   function scrollListener() {
  //     window.addEventListener("load", handleScroll);
  //     window.addEventListener("scroll", handleScroll);
  //   } //  window 에서 스크롤을 감시 시작
  //   scrollListener(); // window 에서 스크롤을 감시
  //   return () => {
  //     window.removeEventListener("load", handleScroll);
  //     window.removeEventListener("scroll", handleScroll);
  //   }; //  window 에서 스크롤을 감시를 종료
  // }); // 출처 : https://wazacs.tistory.com/28

  return (
    <>
      <header
        // className={cx(ScrollActive ? "is-fixed" : "", "header", "type_main")}
        className={cx("header", "type_main", "is-fixed")}
      >
        <div className={cx("header_inner")}>
          <h1 className={cx("header_title")}>
            <a href="/" className={cx("header_title_link", "type_basic")}>
              {title}
            </a>
          </h1>

          {menuItem && (
            <nav className={cx("gnb")}>
              <div className={cx("gnb_inner")}>
                {Object.entries(menuItem).map(([key, value], i) => (
                  <a
                    href={"/" + menuItem[key].id}
                    data-value={key}
                    key={key + i}
                    className={cx(
                      (function () {
                        if (rootPage) {
                          return i < 1 ? "is-active" : "";
                        } else {
                          if (
                            url.indexOf(gnbActive(value.id)) !== -1 &&
                            gnbActive(value.id) == value.id
                          ) {
                            return "is-active";
                          }
                        }
                      })(),
                      "gnb_item"
                    )}
                  >
                    {value.title}
                  </a>
                ))}
              </div>
            </nav>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
