import react from "react";

import classNames from "classnames/bind";
import style from "./NotFound.module.scss";

const cx = classNames.bind(style);

import { ReactComponent as ExceptionAlertBasic } from "@Images/exception_alert--basic.svg";
import { ReactComponent as ExceptionAlertBasic1 } from "@Images/error_alert--basic.svg";

function movePage() {
  return window.history.back();
}

const NotFound = () => {
  return (
    <>
      <div id="wrap">
        <div className={cx("exception", "type_default")}>
          <ExceptionAlertBasic width={150} fill={"#f00"} />

          <h1 className={cx("exception_title")}>
            잘못된 경로로 접근하셨어요!
            <br />
            페이지 URL을 확인해 주세요!
          </h1>

          <a
            href="#"
            onClick={movePage}
            className={cx("exception_link", "type_back")}
          >
            이전 페이지로 이동
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
