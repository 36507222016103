import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Main from "./Main";
import Profile from "@Pages/Profile";
import Active from "@Pages/Active";
import Tech from "@Pages/Tech";
import Career from "@Pages/Career";
import Test from "./Test";
import NotFound from "./NotFound";

const sectionItem = {
  profile: {
    id: "profile",
    title: "인물",
    name: "main_profile",
  },
  career: {
    id: "career",
    title: "경력",
    name: "main_career",
  },
  tech: {
    id: "tech",
    title: "기술",
    name: "main_tech",
  },
  active: {
    id: "active",
    title: "활동",
    name: "main_active",
  },
};

type props = {
  menuItem?: any;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path={"/"}
          element={<Profile menuItem={sectionItem} rootPage={"hello"} />}
        />
        <Route path={"/profile"} element={<Profile menuItem={sectionItem} />} />
        <Route path={"/career"} element={<Career menuItem={sectionItem} />} />
        <Route path={"/tech"} element={<Tech menuItem={sectionItem} />} />
        <Route path={"/active"} element={<Active menuItem={sectionItem} />} />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
