import classNames from "classnames/bind";

import style from "./Footer.module.scss";

const cx = classNames.bind(style);

type Props = {
  title?: any;
};

const Footer: React.FC<Props> = ({ title }: Props) => {
  return (
    <>
      <footer className={cx("footer")}>
        <div className={cx("footer_inner")}>
          <p className={cx("footer_text", "type_basic")}>
            Copyright&copy;. Jaesun Park All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
