import React from "react";

import TechLayout from "@Components/layout/TechLayout";

type Props = {
  menuItem?: any;
};

const Tech: React.FC<Props> = ({ menuItem }: Props) => {
  return (
    <>
      <TechLayout menuItem={menuItem} />
    </>
  );
};

export default Tech;
