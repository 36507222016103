import React from "react";

import ActiveLayout from "@Components/layout/ActiveLayout";

type Props = {
  menuItem?: any;
};

const Active: React.FC<Props> = ({ menuItem }: Props) => {
  return (
    <>
      <ActiveLayout menuItem={menuItem} />
    </>
  );
};

export default Active;
