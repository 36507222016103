import React from "react";

import CareerLayout from "@Components/layout/CareerLayout";

type Props = {
  menuItem?: any;
};

const Career: React.FC<Props> = ({ menuItem }: Props) => {
  return (
    <>
      <CareerLayout menuItem={menuItem} />
    </>
  );
};

export default Career;
