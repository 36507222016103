import classNames from "classnames/bind";

import style from "./Tech.module.scss";

const cx = classNames.bind(style);

type Props = {
  title?: any;
  name?: any;
  id?: any;
};

const Tech: React.FC<Props> = ({ title, name, id }: Props) => {
  return (
    <>
      <div className={cx("main_tech")} id={id}>
        <div className={cx("main_tech_inner")}>
          <h3 className={cx("main_tech_title")}>기술</h3>

          <div className={cx("main_tech_introduce")}>
            <div className={cx("main_introduce_skills")}>
              <h4 className={cx("main_skills_title")}>스킬</h4>

              <div className={cx("main_skills_about")}>
                <dl className={cx("main_about_description")}>
                  <dt className={cx("main_description_title")}>
                    빠르게 변화하는 기술 생태계에 대한 대응
                  </dt>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>HTML / CSS</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        시멘틱한 마크업 작업을 지향합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        포털 서비스 업무를 진행하면서 자주 느꼈었던 생각인데요.
                        CSS의 중복 선언을 최소화하거나 없는 작업 방식을
                        선호합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        프로젝트에 중도 합류한 인원도 쉽게 파악할 수 있도록
                        구조를 설계합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        모든 프로젝트에 Sass를 사용합니다. 기본 기능에 대한
                        활용과 반복문 및 조건문 등을 이용하여 작업 능률을 높일
                        수 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        무분별한 미디어 쿼리의 사용은 지양합니다. 디자이너와의
                        커뮤니케이션을 통해 최소한의 break point를 설정하도록
                        합니다.
                      </li>
                    </ul>
                  </dd>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>Javascript</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        Webpack, Gulp 등 자동화 도구에 대해 유지 보수 및 기능을
                        추가할 수 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        특별한 경우가 아닌 이상, 무분별한 Javascript 사용은
                        지양합니다. CSS의 기능만으로도 해결할 수 있는 이슈에서는
                        Javascript의 사용을 지양합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        Javascript 및 jQuery 등 기존 문서들에 대해 파악할 수
                        있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        간단한 모션 및 기타 업무에 필요한 스크립트를 구현할 수
                        있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        디자이너와 개발 등 유관 부서와의 원활한 커뮤니케이션이
                        가능합니다.
                      </li>
                    </ul>
                  </dd>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>React</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        React에 관심이 많습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        부동산 업무를 진행하면서 개발과 커뮤니케이션을 진행한
                        경험이 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        UI와 관련한 초기 세팅을 무리 없이 수행할 수 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        처음 프로젝트를 시작할 때 개발 조직과 협의하여
                        <a
                          href="https://wit.nts-corp.com/2021/08/11/6461/"
                          className={cx(
                            "main_item_link",
                            "type_basic",
                            "type_emphasis"
                          )}
                          target="_blank"
                        >
                          🔗 VAC 패턴
                        </a>
                        으로 개발을 진행한 경험이 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        Typescript 방식을 선호합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        데이터를 이용한 자동화에 관심이 많습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        기존 업무 경험으로 인하여, React 프로젝트에 투입되어
                        업무를 진행함에 있어 무리가 없습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        처음 프로젝트를 시작할 때 개발에서 세팅해 준 storybook을
                        활용하여 히스토리 관리 및 커뮤니케이션을 한 경험이
                        있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        현 사이트를 React Typescript 기반으로 구축하였습니다.
                        <a
                          href="https://gitlab.cony.kr/portfolio/portfolio.new/-/tree/develop"
                          className={cx(
                            "main_item_link",
                            "type_basic",
                            "type_emphasis"
                          )}
                          target="_blank"
                        >
                          🔗 저장소
                        </a>
                        <a
                          href="https://gitlab.cony.kr/portfolio/portfolio.new/-/issues"
                          className={cx(
                            "main_item_link",
                            "type_basic",
                            "type_emphasis"
                          )}
                          target="_blank"
                        >
                          🔗 이슈 목록 페이지
                        </a>
                        <a
                          href="https://gitlab.cony.kr/portfolio/portfolio.new/-/network/develop"
                          className={cx(
                            "main_item_link",
                            "type_basic",
                            "type_emphasis"
                          )}
                          target="_blank"
                        >
                          🔗 작업 히스토리
                        </a>
                      </li>
                    </ul>
                  </dd>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>디자인 관련</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        Sketch : 기존에 작업된 스케치 파일에 대해 간단한 수정 및
                        파일 오류를 수정할 수 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        Zeplin, Figma : 해당 도구로 5년 넘게 작업을 한 경험이
                        있어, 능숙하게 디자인 가이드 파악할 수 있으며, 파일에
                        대한 오류를 발견하여 수정 및 디자이너와 커뮤니케이션이
                        가능합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        Photoshop : 능숙히 다룰 수 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        SVG : Vector 방식의 SVG 파일의 사용을 선호합니다.
                        디자이너와 UI 개발자 모두 Win-win 할 수 있는 아주 좋은
                        파일이라고 생각합니다.
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
            <div className={cx("main_introduce_skills")}>
              <h4 className={cx("main_skills_title")}>
                업무 진행 방식 및 방법
              </h4>
              <div className={cx("main_skills_about")}>
                <dl className={cx("main_about_description")}>
                  <dt className={cx("main_description_title")}>
                    다양한 경험과 여러 사람들과의 커뮤니케이션, 그리고 타인의
                    방식을 존중하는 마인드 및 시너지를 내기 위한 고민
                  </dt>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>서비스 리딩</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        2018년부터 신규 입사자를 케어하였습니다. 주니어를 케어한
                        경험도 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        탑 다운 방식은 지양합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        신입의 경우, 케어하는 동료에게 최소한의 컨벤션만 지켜줄
                        것을 요구합니다. 너무 까다로울 정도로 하나하나 통제하지
                        않습니다. 경험이 많지 않은 신입에게 너무 많은 통제를
                        하게 되면, 성장을 하는 데 있어 지장이 있다고 생각하기
                        때문입니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        신규 입사자에겐 드라마 "미생"에 나온 문장 줄이기를
                        해보도록 제안해 봅니다. 개발자가 개발 업무를 하는 것도
                        중요하지만, 글을 쓰거나 문장을 줄이는 방법을 아는 것도
                        중요하다고 생각하기 때문입니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        신입 ~ 3년차 동료와의 코드 리뷰를 진행함에 있어 "~
                        방식으로 하세요."가 아닌, "~ 방식이 좋을 것 같은데,
                        어떻게 생각하시나요?" 또는 "~ 방식이 좋아서 제시한
                        방식대로 하는게 더 좋을 것 같습니다."라는 식으로 진행을
                        합니다. 탑다운 방식이 아닌 여러 의견을 제시함으로 넓은
                        시야로 업무를 수행할 수 있도록 도움을 주는 방식을
                        지향합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        4~5년 고 연차 주니어와 업무를 진행할 경우 서로의 작업
                        방식을 존중하며 업무를 진행합니다. 서로의 방식을
                        제안하며 시너지를 내려 노력합니다.
                      </li>
                    </ul>
                  </dd>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>이슈</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        신규 이슈가 발생하면, 작업에 해당하는 직군에 대해 빠르게
                        파악할 수 있습니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        업무를 진행하면서 발생하는 부분에 대해 이슈 페이지를
                        통하여 모든 기록을 남겨 둡니다.
                      </li>
                    </ul>
                  </dd>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>코드 리뷰</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        아주 사소한 건에 대해서는 필요한 경우에만 코드 리뷰를
                        진행합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        리뷰를 진행함에 있어 명령이 아닌, 리뷰어의 의견을
                        제안하는 방식을 지향합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        타인의 의견을 존중하고 타협하여 리뷰가 원활하게 진행될
                        수 있도록 노력합니다.
                      </li>
                    </ul>
                  </dd>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>커뮤니케이션</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        내 일에만 관심을 가지는 것이 아닌, 직군에 선을 긋지 않고
                        이슈가 원활히 진행될 수 있도록 기여합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        디자인 직군과의 커뮤니케이션에 조금 더 신경을 쓰는
                        편입니다. 메일이나 메신저로 대화를 하면서 최대한
                        디자이너가 원하는 방식에 대해 고민을 하여, 퀄리티 높은
                        결과물이 나올 수 있도록 도움을 줍니다. 간혹 프로젝트를
                        진행하다 보면, 개발 입김에 디자이너가 위축이 되는
                        케이스를 종종 본 적이 있습니다. 그런 경우 따로 DM으로
                        디자이너가 원하는 결과에 대해 얘기를 하고, 의도한
                        결과물이 나올 수 있도록 합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        같은 직군의 동료에게 업무와 관련하여 터치를 일절 하지
                        않습니다. 상대의 방식을 존중합니다. 자유로운 방식으로
                        업무를 진행하는 것을 선호합니다.
                      </li>
                    </ul>
                  </dd>
                  <dd className={cx("main_description_data")}>
                    <em className={cx("main_data_title")}>개발</em>

                    <ul className={cx("main_data_list", "type_dot")}>
                      <li className={cx("main_list_item")}>
                        마크업과 CSS 등 공통화에 대하여 신중하게 생각합니다.
                        작업자 본인만이 알 수 있는 공통화는 공통화에 대한 의미를
                        많이 퇴색 시키는 방식이라고 생각합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        CSS의 중복 선언을 지양합니다. 유지 보수에 있어 좋지 않은
                        방식이라고 생각하며, 레거시화 되어가는 과정 중에
                        하나라고 생각하기 때문입니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        각 코드에 대해 설명이 필요한 부분은 최대한 주석을 남기는
                        편입니다. 다음 작업자가 헤매지 않을 수 있도록 도움을 줄
                        수 있다고 생각하기 때문입니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        <a
                          href="https://techblog.woowahan.com/2553/"
                          className={cx(
                            "main_item_link",
                            "type_basic",
                            "type_emphasis"
                          )}
                          target="_blank"
                        >
                          🔗 Gitflow
                        </a>
                        를 이용한 작업 방식을 선호합니다. 1개의 branch에 master
                        branch만 push 하는 방식은 지양합니다. Git을 능숙하게
                        사용하여 깔끔히 정리된 작업 히스토리를 선호합니다.
                      </li>
                      <li className={cx("main_list_item")}>
                        유지 보수 업무를 여러 해 해오면서, 각 요소가 들어가고
                        빠질 때에 대해 항상 고민합니다. 어떠한 영역이 빠져도
                        화면에 영향이 없는 작업 방식을 선호합니다. 개발에서
                        임의로 영역을 제거하였는데 화면이 깨진다면 다시 작업을
                        해야 하는 번거로움을 방지하기 위해 많은 고민을 합니다.
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tech;
