import React from "react";

import ProfileLayout from "@Components/layout/ProfileLayout";

type Props = {
  menuItem?: any;
  rootPage?: any;
};

const Profile: React.FC<Props> = ({ menuItem, rootPage }: Props) => {
  return (
    <>
      <ProfileLayout menuItem={menuItem} rootPage={rootPage} />
    </>
  );
};

export default Profile;
