import React from "react";

const SkipNavigation: React.FC = () => {
  return (
    <div className="u_skip">
      <a href="#content">콘텐츠 영역 바로가기</a>
    </div>
  );
};

export default SkipNavigation;
