export function dateCompare(setOld, setNew) {
  const oldDate = new Date(setOld);
  const newDate = new Date(setNew);
  let total = NaN;
  let diff = Math.abs(
    (newDate.getFullYear() - oldDate.getFullYear()) * 12 +
      (newDate.getMonth() + 1 - oldDate.getMonth())
  );

  if (diff >= 12) {
    return (
      Math.floor(diff / 12) +
      "년 " +
      (diff % 12 !== 0 ? (diff % 12) + "개월" : "")
    );
  } else if (diff == 0) {
    return 1 + "개월";
  } else {
    return diff + "개월";
  }
}
