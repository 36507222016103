import classNames from "classnames/bind";

import style from "./Active.module.scss";

const cx = classNames.bind(style);

type Props = {
  title?: any;
  name?: any;
  id?: any;
};

const Active: React.FC<Props> = ({ title, name, id }: Props) => {
  return (
    <>
      <div className={cx("main_active")} id={id}>
        <div className={cx("main_active_inner")}>
          <h3 className={cx("main_active_title")}>활동</h3>

          <div className={cx("main_active_history")}>
            <ol className={cx("main_history_list", "type_basic")}>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2021.07.14.
                  </em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    우아한테크코스 4기 - UI개발 특강
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : 우아한테크코스 교육생
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        FE 교육생들을 대상으로 한 특강을 진행하였습니다.
                      </p>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_promotion")}
                            href="https://techblog.woowahan.com/2724/"
                            target="_blank"
                          >
                            🔗 교육 과정 URL
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_promotion")}
                            href="https://hello.cony.kr/"
                            target="_blank"
                          >
                            🔗 특강 자료 URL
                          </a>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2017. ~ 2018.
                  </em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    Git 강의형 스터디
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : 입문자
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        Git을 처음 사용해 보고자 하는 사람들을 대상으로 한
                        강의형 스터디를 진행하였습니다.
                      </p>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_promotion")}
                            href="https://files.cony.kr/git_0.pptx"
                            target="_blank"
                          >
                            🔗 강의 자료 다운로드(ppt, 기본형) - 슬라이드 쇼를
                            재생하면 과정을 볼 수 있습니다.
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_promotion")}
                            href="https://files.cony.kr/rebase.pptx"
                            target="_blank"
                          >
                            🔗 강의 자료 다운로드(ppt, 심화형(rebase)) -
                            슬라이드 쇼를 재생하면 과정을 볼 수 있습니다.
                          </a>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2017. ~ 2018.
                  </em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    NTS 사내 Git 교육
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : 신규 입사자
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        신규 입사자들이 입사 후 추가로 받는 교육을
                        진행하였습니다.
                      </p>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_promotion")}
                            href="https://files.cony.kr/git_0.pptx"
                            target="_blank"
                          >
                            🔗 강의 자료 다운로드(ppt, 기본형) - 슬라이드 쇼를
                            재생하면 과정을 볼 수 있습니다.
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_promotion")}
                            href="https://files.cony.kr/rebase.pptx"
                            target="_blank"
                          >
                            🔗 강의 자료 다운로드(ppt, 심화형(rebase)) -
                            슬라이드 쇼를 재생하면 과정을 볼 수 있습니다.
                          </a>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2016. ~ 2018.
                  </em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    Sass 강의형 스터디
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : 입문자
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        Sass를 처음 사용해 보고자 하는 사람들을 대상으로 한
                        일회성 강의형 스터디를 진행하였습니다.
                      </p>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_promotion")}
                            href="https://sass.cony.kr/"
                            target="_blank"
                          >
                            🔗 강의 자료 URL
                          </a>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2015. ~ 2016.
                  </em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    자바스크립트 토론형 스터디
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : UI 개발자
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        스터디 구성원들이 책을 읽고 토론하는 형식의 스터디를
                        진행한 경험이 있습니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>2015.</em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    하드코딩하는 사람들 세미나 발표
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : 연말 세미나 참가자
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        네이버 카페 하드코딩하는 사람들의 연말 세미나에서 발표를
                        하였습니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2015. ~ 2017.
                  </em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    UI 개발 스터디
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : UI 개발자
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        UI 개발자들을 대상으로 한 강의형 스터디를
                        진행하였습니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>2014 ~</em>

                  <strong className={cx("main_head_title", "type_emphasis")}>
                    각종 여러 강의형 및 토론형 스터디
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    대상 : -
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}></dt>
                    <dd className={cx("main_description_data")}>
                      <p className={cx("main_data_text", "type_detail")}>
                        각종 여러 강의형 및 토론형 스터디를 진행하거나 참여한
                        적이 있습니다.
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default Active;
